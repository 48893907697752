<template>
	<Dialog ref='viewMediaModal' v-model:visible="display" :modal="true" :closable="false" class='view-media-modal' @show="onShown()" >
		
		<div >
			<video ref="videoPlayer" class="video-js"></video>
		</div>
		
		<template #footer>
			<Button label="Close" class='cancel p-button-raised p-button-rounded' @click='close()'/>
			
		</template>
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import videojs from 'video.js';
export default {
    name: 'ViewMediaModal',
	
    data() {
		return {
			
			display: false,
			
			options: {
				autoplay: false,
				controls: true,
				poster: null,
				fluid:true,
				preload: 'auto',

				sources: [
				
				]
			},
			player: null,
		}
    },

	components: {
		Dialog
	},

	computed: {
		
	},

	methods: {
		
		open(type, url, thumbnail) {
			this.options.sources.push({src:url, type:type})
			if(thumbnail){
				this.options.poster = thumbnail;
				this.options.preload = 'none';
			}
			else {
				this.options.poster = null;
				this.options.preload = 'auto';
			}
			// this.$nextTick(() => {
			// 	this.player = videojs(this.$refs.videoPlayer, this.options, () => {
			// 	this.player.log('onPlayerReady', this);
			// });
			// })
			
			
			
			this.display = true;

			
		},

		onShown() {
			this.player = videojs(this.$refs.videoPlayer, this.options, () => {
				this.player.log('onPlayerReady', this);
				});
		}, 
		close(){
			if (this.player) {
				this.player.dispose();
			}
			this.options.sources = [];
			this.options.poster = null;
			this.options.preload = 'auto';
			this.display = false;
		}
	}

}
</script>

<style>
.view-media-modal {
	font-family: "Trebuchet MS", "Verdana";
	box-shadow:  0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
	width: 70rem;
}

.view-media-modal .p-dialog-header {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.view-media-modal .p-dialog-header .p-dialog-title {
	font-weight: bold;
    font-size: 24px;
}

.view-media-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 0;
}

.view-media-modal .p-dialog-footer {
	padding-top: 1.5rem;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

.conformation-container {
    margin: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
}

@media (max-width: 760px) {
    .view-media-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .view-media-modal .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .view-media-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

	
}
</style>
<style scoped>
	
 .view-media-modal .p-dialog-footer .p-button {
	background: #FFFFFF;
	font-family: "Trebuchet MS", "Verdana";
	font-weight: 600;
	padding: 0.75rem 2.75rem;
	color: #32364E;
	border: 2px solid
 }

 .view-media-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 1rem;
 }

 .view-media-modal .p-dialog-footer .accept {
	border-color: #E63E3E;
 }

 .view-media-modal .p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #E63E3E;
	border: 2px solid #E63E3E;
 }

 .view-media-modal .p-dialog-footer .accept-green {
	border-color: #33CC99;
 }

 .view-media-modal .p-dialog-footer .accept-green:hover {
	color: #FFFFFF;
	background: #33CC99;
	border: 2px solid #33CC99;
 }

 .view-media-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
	border: 2px solid #32364E;
 } 
</style>
